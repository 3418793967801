<template>
  <div class="d-flex align-center justify-center">
    <v-card
      elevation="5"
      border="md opacity-4"
      :width="$vuetify.breakpoint.mdAndUp ? '600' : '100%'"
      class="mx-auto"
      :class="$vuetify.breakpoint.mdAndUp ? 'pa-8' : ''"
    >
      <v-img
        class="pa-4 mx-auto"
        max-width="200px"
        :src="require('@/assets/logo.png')"
        contain
      ></v-img>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-form @keyup.native.enter="submitForm" id="registerForm">
          <v-stepper v-model="currentStep" flat vertical>
            <v-stepper-step
              :complete="currentStep > 1"
              step="1"
              editable
              :rules="[
                () =>
                  !username ||
                  !password ||
                  !email ||
                  !first_name ||
                  !last_name ||
                  !address ||
                  !postal_code ||
                  !phone
                    ? false
                    : true
              ]"
            >
              {{ $t("register.step1") }}
            </v-stepper-step>

            <v-stepper-step :complete="currentStep > 2" step="2" editable>
              {{ $t("register.step2") }}
            </v-stepper-step>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Username"
                      rules="required|min:6|alpha_num"
                    >
                      <v-text-field
                        v-model="username"
                        :error-messages="errors"
                        outlined
                        name="Username"
                        :label="$t('login.usernameLabel')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        outlined
                        name="Email"
                        :label="$t('login.emailLabel')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('login.passwordName')"
                      rules="required|min:6"
                    >
                      <v-text-field
                        id="password"
                        :error-messages="errors"
                        :name="$t('login.passwordName')"
                        outlined
                        :label="$t('login.passwordLabel')"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.first_name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="first_name"
                        :error-messages="errors"
                        outlined
                        :name="$t('register.first_name')"
                        :label="$t('register.first_name')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.last_name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="last_name"
                        :error-messages="errors"
                        outlined
                        :name="$t('register.last_name')"
                        :label="$t('register.last_name')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.address')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="address"
                        :error-messages="errors"
                        outlined
                        :name="$t('register.address')"
                        :label="$t('register.address')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.cityName')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="cityName"
                        :error-messages="errors"
                        outlined
                        :name="$t('register.cityName')"
                        :label="$t('register.cityName')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.postal_code')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="postal_code"
                        :error-messages="errors"
                        outlined
                        :name="$t('register.postal_code')"
                        :label="$t('register.postal_code')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.phone')"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="phone"
                        :error-messages="errors"
                        outlined
                        :name="$t('register.phone')"
                        :label="$t('register.phone')"
                        type="text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.company')"
                      rules="required"
                    >
                      <v-autocomplete
                        autocomplete="off"
                        :items="companies"
                        small-chips
                        item-text="name"
                        item-value="id"
                        @change="onCompanyChange($event)"
                        deletable-chips
                        v-model="company"
                        :placeholder="$t('register.companyLabel')"
                        :error-messages="errors"
                        :label="$t('register.companyLabel')"
                        clearable
                        outlined
                      >
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.city')"
                      rules="required"
                    >
                      <v-autocomplete
                        autocomplete="off"
                        :items="cities"
                        small-chips
                        item-text="name"
                        item-value="id"
                        @change="onCityChange($event)"
                        deletable-chips
                        :disabled="!company"
                        v-model="city_id"
                        :placeholder="$t('register.cityLabel')"
                        :error-messages="errors"
                        :label="$t('register.cityLabel')"
                        clearable
                        outlined
                      >
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="$t('register.shop')"
                      rules="required"
                    >
                      <v-autocomplete
                        autocomplete="off"
                        :items="shops"
                        small-chips
                        item-text="address"
                        item-value="id"
                        deletable-chips
                        :disabled="!city_id && !company"
                        v-model="shop_id"
                        :placeholder="$t('register.shopLabel')"
                        :error-messages="errors"
                        :label="$t('register.shopLabel')"
                        clearable
                        outlined
                      >
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
            <v-row no-gutters>
              <v-col cols="12" class="pr-6 text-right" v-if="currentStep == 1">
                <v-btn
                  :disabled="
                    !username ||
                    !password ||
                    !email ||
                    !first_name ||
                    !last_name ||
                    !address ||
                    !cityName ||
                    !postal_code ||
                    !phone
                  "
                  color="primary"
                  @click="currentStep = 2"
                  class="text-transform"
                  >{{ $t("register.nextStep") }}
                  <v-icon small right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pl-6" v-if="currentStep == 2">
                <v-btn color="primary" @click="currentStep = 1" class="text-transform">
                  <v-icon small left>mdi-arrow-left</v-icon>{{ $t("register.previousStep") }}
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" class="pr-6 text-right" v-if="currentStep == 2">
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  :loading="loading"
                  @click="submitForm"
                  class="text-transform"
                  >{{ $t("login.register") }}
                  <v-icon small right>mdi-exit-to-app</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-right my-3 pr-6" v-if="currentStep == 2">
                <router-link class="primary--text" :to="{ name: 'login' }"
                  >{{ $t("login.login") }}
                </router-link>
              </v-col>
            </v-row>
          </v-stepper>
        </v-form>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver
  },
  data() {
    return {
      email: null,
      username: null,
      showPassword: false,
      password: null,
      first_name: null,
      last_name: null,
      address: null,
      postal_code: null,
      cityName: null,
      phone: null,
      city_id: null,
      shop_id: null,
      company: null,
      currentStep: 1,
      text: ""
    };
  },
  computed: {
    ...mapGetters({
      loading: "options/loading",
      companies: "companies/items",
      cities: "companies/cities",
      shops: "companies/shops"
    })
  },
  created() {
    this.$store.dispatch("companies/getItems");
  },
  methods: {
    onCompanyChange(value) {
      this.$store.commit("companies/setPaginationValue", {
        name: "salesCompaniesIds",
        value: value
      });
      this.city_id = null;
      this.shop_id = null;
      this.$store.dispatch("companies/getCities");
    },
    onCityChange(value) {
      this.$store.commit("companies/setPaginationValue", {
        name: "citiesIds",
        value: value
      });
      this.shop_id = null;
      this.$store.dispatch("companies/getShops");
    },
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const credentials = {
          email: this.email,
          username: this.username,
          password: this.password,
          firstName: this.first_name,
          lastName: this.last_name,
          address: this.address,
          cityName: this.cityName,
          postalCode: this.postal_code,
          phone: this.phone,
          cityId: this.city_id,
          shopId: this.shop_id
        };
        this.$auth
          .register({
            data: credentials
          })
          .then(() => {
            this.$router.push({
              name: "thankYou"
            });
          });
      }
    }
  }
};
</script>
<style scoped></style>
